import React, { Suspense, lazy } from 'react';
import { INVESTOR_ID, LOG_IN, MA_ID, SIGN_UP } from 'constant';
import { PrivateSuspense, PublicSuspense, profileChildrens } from './Helper';

const Dashboard = lazy(() => import(`pages/Dashboard`));
const OnBoarding = lazy(() => import('pages/OnBoarding'));
const ForgotPassword = lazy(() => import('pages/Authentication/components/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Authentication/components/ResetPassword'));
const Authentication = lazy(() => import('pages/Authentication'));
const Landing = lazy(() => import('pages/Landing'));
const User = lazy(() => import('pages/SuperAdmin/User'));
const Opportunity = lazy(() => import('pages/Opportunity'));
const Audit = lazy(() => import('pages/SuperAdmin/Audit'));
const Approvals = lazy(() => import('pages/SuperAdmin/Approvals'));
const OpportunityDetail = lazy(() => import('pages/Opportunity/components/OpportunityDetails'));
const OpportunityDetailsMobile = lazy(() =>
  import('pages/Opportunity/components/OpportunityDetailsMobile')
);
const Profile = lazy(() => import('pages/Profile'));
const LoginSuccess = lazy(() => import('pages/Authentication/components/LoginSuccess'));
const Portfolio = lazy(() => import('pages/Portfolio'));
const TermsService = lazy(() => import('pages/Authentication/components/TermsService'));
const PrivacyPolicy = lazy(() => import('pages/Authentication/components/PrivacyPolicy'));
const TermsAndPolicies = lazy(() => import('pages/TermsAndPolicy'));
const CreateOpportunity = lazy(() => import('pages/Opportunity/components/CreateOpportunity'));
const Settings = lazy(() => import('pages/Settings'));
const Agents = lazy(() => import('pages/Agent'));
const ComingSoon = lazy(() => import('pages/ComingSoon'));
const FindOpportunity = lazy(() => import('pages/FindOpportunity'));
const FindBroker = lazy(() => import('pages/Finders/Broker'));
const LenderFinder = lazy(() => import('pages/LenderFinder'));
const ReassignedOpportunity = lazy(() => import('pages/ReassignedOpportunity'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const Investors = lazy(() => import('pages/Investors'));
const InvestorsDetails = lazy(() => import('pages/Investors/components/InvestorDetails'));
const FindMortgageAdmin = lazy(() => import('pages/FindMortgageAdmin'));
const Chat = lazy(() => import('pages/Chat'));
const PageLoader = lazy(() => import('components/PageLoader'));

const routes = (user) => {
  const allRoutes = [
    {
      path: '/',
      element: (
        <PublicSuspense>
          <Landing />
        </PublicSuspense>
      )
    },
    {
      path: '/login',
      element: (
        <PublicSuspense>
          <Authentication authType={LOG_IN} />
        </PublicSuspense>
      )
    },
    {
      path: '/signup/:id',
      element: (
        <PublicSuspense>
          <Authentication authType={SIGN_UP} />
        </PublicSuspense>
      ),
      errorElement: <PageNotFound />
    },
    {
      path: '/onboarding',
      element: (
        <PrivateSuspense flag={true}>
          <OnBoarding />
        </PrivateSuspense>
      )
    },
    {
      path: '/forgot-password',
      element: (
        <PublicSuspense>
          <ForgotPassword />
        </PublicSuspense>
      )
    },
    {
      path: '/reset-password',
      element: (
        <PublicSuspense>
          <ResetPassword />
        </PublicSuspense>
      )
    },
    {
      path: '/dashboard',
      element: (
        <Suspense fallback={<PageLoader />}>
          <PrivateSuspense>
            <Dashboard />
          </PrivateSuspense>
        </Suspense>
      )
    },
    {
      path: '/agents',
      element: (
        <PrivateSuspense>
          <Agents />
        </PrivateSuspense>
      )
    },
    {
      path: '/opportunity/:id',
      element: (
        <PrivateSuspense>
          <Opportunity />
        </PrivateSuspense>
      )
    },
    {
      path: '/find-opportunity',
      element: (
        <PrivateSuspense>
          <FindOpportunity />
        </PrivateSuspense>
      )
    },
    {
      path: '/create-opportunity',
      element: (
        <PrivateSuspense>
          <CreateOpportunity />
        </PrivateSuspense>
      )
    },
    {
      path: '/opportunity-detail/:id/edit/:path?',
      element: (
        <PrivateSuspense>
          <CreateOpportunity />
        </PrivateSuspense>
      )
    },
    {
      path: '/opportunity-detail/:id',
      element: (
        <PrivateSuspense>
          <OpportunityDetail />
        </PrivateSuspense>
      )
    },
    {
      path: '/opportunity-detail-mobile/:opportunityId/:chatUserId',
      element: (
        <PrivateSuspense>
          <OpportunityDetailsMobile />
        </PrivateSuspense>
      )
    },
    {
      path: '/audit',
      element: (
        <PrivateSuspense>
          <Audit />
        </PrivateSuspense>
      )
    },
    {
      path: '/approvals/:id',
      element: (
        <PrivateSuspense>
          <Approvals />
        </PrivateSuspense>
      )
    },
    {
      path: '/users/:id',
      element: (
        <PrivateSuspense>
          <User />
        </PrivateSuspense>
      )
    },
    //super admin users
    {
      path: '/user/:title/:id',
      element: (
        <PrivateSuspense>
          <Profile />
        </PrivateSuspense>
      ),
      children: profileChildrens
    },
    //profile approve users
    {
      path: '/approval/:title/:id',
      element: (
        <PrivateSuspense>
          <Profile />
        </PrivateSuspense>
      ),
      children: profileChildrens
    },
    {
      path: '/complete-profile',
      element: (
        <PrivateSuspense>
          <Profile />
        </PrivateSuspense>
      ),
      children: profileChildrens
    },
    {
      path: '/profile',
      element: (
        <PrivateSuspense>
          <Profile />
        </PrivateSuspense>
      ),
      children: profileChildrens
    },

    {
      path: '/settings',
      element: (
        <PrivateSuspense>
          <Settings />
        </PrivateSuspense>
      )
    },
    {
      path: '/terms-policies',
      element: (
        <PrivateSuspense isNotHeaderSidebar={true}>
          <TermsAndPolicies />
        </PrivateSuspense>
      )
    },
    {
      path: '/loginSuccess',
      element: (
        <PublicSuspense>
          <LoginSuccess />
        </PublicSuspense>
      )
    },
    {
      path: '/term-service',
      element: (
        <PublicSuspense>
          <TermsService />
        </PublicSuspense>
      )
    },
    {
      path: '/privacy-policy',
      element: (
        <PublicSuspense>
          <PrivacyPolicy />
        </PublicSuspense>
      )
    },
    {
      path: '/find-broker',
      element: (
        <PrivateSuspense>
          <FindBroker />
        </PrivateSuspense>
      )
    },
    {
      path: '/lender-finder',
      element: (
        <PrivateSuspense>
          <LenderFinder />
        </PrivateSuspense>
      )
    },
    {
      path: '/reassigned-opportunity',
      element: (
        <PrivateSuspense>
          <ReassignedOpportunity />
        </PrivateSuspense>
      )
    },
    {
      path: '/investors',
      element: (
        <PrivateSuspense>
          <Investors />
        </PrivateSuspense>
      )
    },
    {
      path: '/investors-details/:id',
      element: (
        <PrivateSuspense>
          <InvestorsDetails />
        </PrivateSuspense>
      )
    },
    {
      path: '/proposals/:id',
      element: (
        <PrivateSuspense>
          <Portfolio />
        </PrivateSuspense>
      )
    },
    {
      path: 'find-mortgage-admin',
      element: (
        <PrivateSuspense>
          <FindMortgageAdmin />
        </PrivateSuspense>
      )
    },
    {
      path: 'opportunity-chat/:id',
      element: (
        <PrivateSuspense>
          <Chat />
        </PrivateSuspense>
      )
    },
    {
      path: '*',
      element: (
        <PrivateSuspense>
          <ComingSoon />
        </PrivateSuspense>
      )
    }
  ];

  if (user && user.roleId && (user.roleId === INVESTOR_ID || user.roleId === MA_ID)) {
    const findIndexRoute = allRoutes.findIndex((item) => item.path === '/opportunity/:id');

    if (findIndexRoute > -1) {
      allRoutes.splice(findIndexRoute, 1);
    }
  }
  return allRoutes;
};

export { routes };
